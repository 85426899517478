<template>
    <div>
        <v-container>
            <v-row>
                <div class="col-md-12 col-sm-12">
                    <v-card dense>
                        <v-card-title>
                            <h3>{{ "Despacho por Picking" }}</h3>
                        </v-card-title>
                        <v-card-title>
                            <v-row>
                                <div class="col-md-12 col-sm-12">
                                    <v-card dense >
                                        <!-- <v-row  style="margin: auto;">
                                            sfdkfnk
                                        </v-row>
                                        <v-divider ></v-divider> -->
                                        <v-row style="margin: auto;">		
                                            <v-col cols="auto">                                               
                                                <s-date label="Fecha Inicio"
                                                    v-model="DateBegin"
                                                    @input="searchitemsSend($event)"
                                                    >
                                                </s-date>
                                            </v-col>
                                            <v-col cols="auto">
                                                <s-date label="Fecha Fin"
                                                    v-model="DateEnd"
                                                    @input="searchitemsSend($event)"
                                                    >
                                                </s-date>
                                            </v-col>
                                            <v-col cols="auto">     
                                                <s-text label="Codigo Prepacking"
                                                    v-model="CppCorrelative"
                                                    @input="searchitemsSend($event)"
                                                    >
                                                </s-text>                                                   
                                            </v-col>
                                            <v-col  cols="auto" >
                                                <s-select-client
                                                    clearable
                                                    label="Cliente(es):"
                                                    v-model="filter.CumID"
                                                    ref="txtCumID"
                                                    full 
                                                     @input="searchitemsSend($event)"
                                                    >
                                                </s-select-client>      
                                            </v-col>
                                            <v-col cols="auto">                              
                                                <s-select-definition
                                                    clearable
                                                    label="Tipo corte"
                                                    :def="1429"
                                                    v-model="filter.TypeCut"
                                                     @input="searchitemsSend($event)"
                                                ></s-select-definition>    
                                            </v-col>
                                            <v-col cols="auto">
                                                <s-select-definition
                                                    clearable
                                                    label="Tipo Empaque"
                                                    v-model="filter.TypePacking"
                                                    :def="1435"
                                                     @input="searchitemsSend($event)"
                                                ></s-select-definition>
                                            </v-col>
                                        </v-row>

                                        <div class="row">
                                            <div class="col-md-12">
                                                <v-data-table
                                                    dense
                                                    :headers="headersPrePacking"
                                                    :items="itemsPrePacking"
                                                    :items-per-page="-1"
                                                    disable-sort
                                                    v-model="PrePackingListDispatch"
                                                    
                                                    item-key="CppID"
                                                    >
                                                    <template v-slot:item.picking="{ item }">
                                                        <v-btn
                                                            x-small
                                                            color="info"
                                                            fab
                                                            @click="DispatchByPicking(item)"
                                                        >
                                                            <v-icon>
                                                                mdi-exit-to-app
                                                            </v-icon
                                                            >
                                                        </v-btn>
                                                    </template>
                                                
                                                </v-data-table>
                                            </div>
                                        </div>

                                    </v-card>
                                </div>
                            </v-row>
                        </v-card-title>
                    </v-card>
                </div>
            </v-row>
        </v-container>
    <v-dialog
        v-model="ModalDispatchPicking"
        v-if="ModalDispatchPicking"
        fullscreen
        persistent
    >
        <dispatch-picking :SelectPrePackingDetail="SelectPrePackingDetail"   :SelectPrePacking="SelectPrePacking" @refreshLisDispatch="refreshLisDispatch()" @closeDispatchPicking="closeDispatchPicking()"></dispatch-picking>
    </v-dialog>
    
    </div>
</template>
<script>
    import _sFrzChamberDispatchPickingService from "@/services/FrozenProduction/FrzChamberDispatchPickingService.js";
    // import _sPrePacking from "@/services/FrozenProduction/FrzChamberPrePacking.js";

    import DispatchPicking from "./FrzChamberDispatchPickingDsp.vue"
    import SSelectClient from "@/components/FrozenProduction/Maturation/SSelectClienteMaturation";
    export default{
        components: {DispatchPicking, SSelectClient},
        data(){
            return {
                headersPrePacking: [
                    { text: "Picking", value: "picking" },                    
                    { text: "TRAZABILIDAD", value: "TraceabilityIDs" },
                    { text: "CLIENTE", value: "CumName" },
                    { text: "CAJAS", value: "NumberBox" },       
                    { text: "CULTIVO", value: "TypeCultiveName" },
                    { text: "VARIEDAD", value: "VrtDescription" },
                    { text: "CORTE", value: "TypeCutName" },
                    { text: "PRESENTACION", value: "TypeCutName" },
                    { text: "EMPAQUE", value: "TypePackingName" },
                    { text: "N° VERSION", value: "versionnumber"},
                    { text: "EMBARQUE", value: "numberShipment"},
                    // { text: "EMPAQUE", value: "TypePackingName", width: 20 },
                ],
                itemsPrePacking: [],
                DateBegin: "",
                DateEnd: "",
                CppCorrelative: '',
                PrePackingListDispatch: [],
                SelectPrePacking: {},
                SelectPrePackingDetail:[],
                ModalDispatchPicking: false,
                filter: {},
            }
        },
        watch: {
            
        },
        methods: {
            initialize(){
                // if(this.DateBegin == "" || this.DateEnd == "" || this.CppCorrelative == ""){
				// 	return;
				// }

                let filter = {
                    DateBegin: this.DateBegin, 
                    DateEnd: this.DateEnd,
                    CumID: this.filter.CumID,
                    CppCorrelative : this.CppCorrelative,
                    TypePacking : this.filter.TypePacking,
                    TypeCut : this.filter.TypeCut,
                }
                _sFrzChamberDispatchPickingService.list(filter , this.$fun.getUserID()).then(r => {
                    if(r.status == 200){
                        this.itemsPrePacking = r.data;                                         
                    }
                })
                
            },
            searchitemsSend()
            {
                this.initialize();
            },
            DispatchByPicking(item){
                
                this.ModalDispatchPicking = true;
                this.SelectPrePacking = item;
                this.SelectPrePackingDetail = item.ListPackingDetails
            },
            closeDispatchPicking(){
                this.ModalDispatchPicking = false;
            },
            refreshLisDispatch(){
                this.initialize();
            }

            
            
        },
        created()
        {
            
        },
        mounted(){
            this.initialize();
        }
    }
</script>