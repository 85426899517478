import Service from "../Service";
const resource = "FrzChamberDispatchPicking/";

export default {
  
    
    list(fcb, requestID){
        return Service.post(resource + "list", fcb,{
            params: { requestID: requestID }
        });
    },
    saveDetail(fcb, requestID){
        return Service.post(resource + "saveDetail", fcb,{
            params: { requestID: requestID }
        });
   },

}
