<template>
    
        <v-card>
            <v-card-title>
                <s-toolbar dark :color="'#BAB6B5'" label="Despachar Pallets" close @close="close()" save @save="save()"></s-toolbar>
                
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row justify="center" style="margin:auto">
                    <v-col
                        align="center"
                        cols="12"
                        lg="12"
                        md="12"
                    >   
                        <s-scanner-qr
                            :config="configScann"
                            return-object 
                            @onValue="onValue($event)"
                            :readonly="false"
                            :autofocus="false"
                            :clearInput="clearInput"
                            >
                        </s-scanner-qr>
                    </v-col>
                </v-row>
                    <!-- <v-row justify="center">
                        <v-col cols="2" >
                            <label  aria-hidden="true" class="v-label theme--light"><b>Fecha de carga</b></label>
                            <div class="relojito">
                                <datetime readonly valueZone="America/Lima"  type="date1" v-model="this.PrePackingDispatch.DateLoading">
                                </datetime>
                            </div>
                        </v-col>
                        <v-col cols="2">
                            <s-text readonly label="N° Contenedor" v-model="this.PrePackingDispatch.NumberContainer">
                            </s-text>
                        </v-col>
                   
                        <v-col cols="2">
                            <s-text readonly label="Cliente" v-model="this.PrePackingDispatch.CumName"></s-text>
                        </v-col>
                        <v-col cols="2">
                            <s-text readonly label="N° Cajas" number v-model="this.PrePackingDispatch.NumberBox"></s-text>
                        </v-col>
                        <v-col cols="2">
                            <s-text readonly label="N° Orden de Compra" v-model="this.PrePackingDispatch.NumberPurchase"></s-text>
                        </v-col>
                        <v-col cols="2">
                            <s-text readonly label="Peso Neto" decimal v-model="this.PrePackingDispatch.WeightNet"></s-text>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2">
                            <s-text readonly label="Descripcion de Producto" v-model="this.PrePackingDispatch.ProductDescription"></s-text>
                        </v-col>
                        <v-col cols="2">
                            <s-text readonly label="Peso Bruto" decimal v-model="this.PrePackingDispatch.WeightGross"></s-text>
                        </v-col>
                        <v-col cols="2">
                            <s-text readonly label="Variedad" v-model="this.PrePackingDispatch.VrtName"></s-text>
                        </v-col>
                        <v-col cols="2">
                            <s-text readonly label="Temperatura" number v-model="this.PrePackingDispatch.Temperature"></s-text>
                        </v-col>
                        <v-col cols="2">
                            <s-text readonly label="Presentacion" v-model="this.PrePackingDispatch.Presentation"></s-text>
                        </v-col>
                        <v-col cols="2">
                            <s-text readonly label="Precinto Aduanas" v-model="this.PrePackingDispatch.CustomsSeal"></s-text>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="2">
                            <s-text readonly label="Formato" v-model="this.PrePackingDispatch.FormatString"></s-text>
                        </v-col>
                        <v-col cols="2">
                            <s-text readonly label="Precinto Naviera" v-model="this.PrePackingDispatch.ShippingSeal"></s-text>
                        </v-col>
                        <v-col cols="2">
                            <s-text readonly label="Booking" v-model="this.PrePackingDispatch.Booking"></s-text>
                        </v-col>
                        <v-col cols="2">
                            <s-text readonly label="Precinto Sunshine" v-model="this.PrePackingDispatch.SealSunshine"></s-text>
                        </v-col>
                        <v-col cols="2">
                            <s-text readonly label="Nave" v-model="this.PrePackingDispatch.Ship"></s-text>
                        </v-col>
                        <v-col cols="2">
                            <s-text readonly label="Stickers" v-model="this.PrePackingDispatch.Stickers"></s-text>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2">
                            <s-text readonly label="Naviera" v-model="this.PrePackingDispatch.ShippingCompany"></s-text>
                        </v-col>
                        <v-col cols="2">
                            <s-text readonly label="Otros Stickers" v-model="this.PrePackingDispatch.OthersStickers"></s-text>
                        </v-col>
                        <v-col cols="2">
                            <s-text readonly label="Puerto Destino" v-model="this.PrePackingDispatch.DestinationPort"></s-text>
                        </v-col>
                        <v-col cols="2">
                            <s-text readonly label="N° Termoregistro" v-model="this.PrePackingDispatch.NumberThermoregistration"></s-text>
                        </v-col>
                        <v-col cols="4">
                            <s-textarea readonly label="Observacion " v-model="this.PrePackingDispatch.Observations"></s-textarea>
                        </v-col>
                    </v-row> -->
                    <v-row>
                        <v-card-text style="height: 40px; background: #ecf5ff; -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px; col-md-6;">
                            <h3>Datos Despacho</h3>
                        </v-card-text>
                        <v-col cols="2">
                            <s-select-definition
                                label="Tipo Despacho"
                                :def="1457"
                                v-model="TypeDispatch"
                                clearable
                            >
                            </s-select-definition>
                        </v-col>
                        <v-col cols="2">
                            <s-select-definition
                                label="Almacenes"
                                :def="1450"
                                v-model="CdtWarehouses"
                                v-if="TypeDispatch == 2 "
                                @input ="changeChamber(CdtWarehouses)"
                                clearable
                            >

                            </s-select-definition>
                        </v-col>
                        <v-col cols="2">
                            <s-select
                                v-if="TypeDispatch == 2 && CdtWarehouses !=1"
                                :items="listChamber"
                                label="Camaras"
                                item-text="CdtDescription"
                                item-value="CdtID"
                                v-model="SelectChamber"
                                return-object                        
                                clearable            
                               >
                            </s-select>
                        </v-col>
                        <v-divider vertical></v-divider>
                        
                    </v-row>
                    <div class="row">
                        <div class="col">
                            <v-data-table
                                dense
                                :headers="headersPrePacking"
                                :items="SelectPrePackingDetail"
                                :items-per-page="-1"
                                disable-sort
                                item-key="CpdID"
                                v-model="SelectDispatch"
                                class="elevation-1"
                                :item-class="itemRowBackground"
                                ref="TablePackingDetail"
                                show-select
                            >
                            </v-data-table>
                        </div>
                    </div>
                  
                </v-container>

            </v-card-text>

        </v-card>
        
    
</template>

<script>
    
    import _sPrePackingDispatch from "@/services/FrozenProduction/FrzChamberPrePackingDispatch.js";
    import _sPrePacking from "@/services/FrozenProduction/FrzChamberPrePacking.js";

    import('../../../../styles/linesPacking.css');
    import _sChamber from '@/services/FrozenProduction/FrzChamberDispatchTunnelsChamber.js';

    import _sFrzChamberDispatchPickingService from "@/services/FrozenProduction/FrzChamberDispatchPickingService.js";

    import sScannerQr from '../../../../components/Utils/sScannerQr.vue';

    export default {
        components: { sScannerQr},
        props:{
            SelectPrePacking:{
                // default: null,
                required: false,
                type:Object,
            },
            SelectPrePackingDetail:{
                // default: null,
                required: false,
                type:Array,
            }
        },
        data(){
            return {
                headersPrePacking: [
                    { text: "ID QR", value: "PalletGroupID", width: 20},
                    { text: "Código Producto", value: "TraceabilityID", width: 20 },
                    { text: "Cultivo", value: "TypeCultiveName", width: 20 }, 
                    { text: "Variedad", value: "VrtDescription", width: 20 },                     
                    { text: "Corte", value: "TypeCutName", width: 20 },
                    { text: "Presentacion", value: "TypePresentationName", width: 20 },
                    { text: "N° Cajas", value: "TotalBox", width: 20 },   
                    { text: "Peso Neto (Kg)", value: "TotalBagWeight", width: 60 },
                    { text: "Fecha Vencimiento", value: "DueDateString", width: 20 },
                ],
                TypeDispatch: 0,
                CdtWarehouses: 0,
                listChamber:[],
                SelectChamber: {},
                PrePackingDispatch: {},
                SelectDispatch:[],
                clearInput: false,
                configScann: {}, 
            }
        },
        methods:{
            close(){
                this.$emit("closeDispatchPicking");
            },
            refreshLisDispatch(){
                this.$emit("refreshLisDispatch");
            },
            save(){
                
                if (this.TypeDispatch == 0){
                    this.$fun.alert('Seleccione tipo despacho','warning');
                    return
                }
                if (this.TypeDispatch != 1 && this.CdtWarehouses == 0){
                    this.$fun.alert('Seleccione almacen','warning')
                    return
                }
                if(this.CdtWarehouses == 1){
                    this.$fun.alert('Seleccione otro almacen','warning')
                    return
                }
                if(this.TypeDispatch != 1 && this.CdtWarehouses != 1 && this.SelectChamber.CdtID == undefined){
                    this.$fun.alert('Seleccione Camara','warning')
                    return
                }

                
                let ListPalletDispatch = [];
                this.SelectPrePackingDetail.forEach(element => {
                    ListPalletDispatch.push(element.CpdID);

                });
                let items = {};
                // detalle
                items.ListPalletDispatch = ListPalletDispatch;
                items.TypeDispatch = this.TypeDispatch;
                items.CdtWarehouses = this.CdtWarehouses;
                items.CdtID = this.SelectChamber.CdtID;
                items.UsrCreateID    = this.$fun.getUserID();
                items.UsrUpdateID    = this.$fun.getUserID();
                                
                let pendiente = false;
                                
                if(this.SelectPrePackingDetail.length != this.SelectDispatch.length)
                {                        
                    this.$fun.alert('Faltan pallets por despachar','warning');      
                    pendiente = true;                
                    return;
                }                
                
                if(!pendiente){
                    this.$fun.alert("Desea realizar despacho", "question").then(r => {
                    if(r.value)
                    {

                        _sFrzChamberDispatchPickingService.saveDetail(items, this.$fun.getUserID())
                        .then((r) => {
                            if(r.status == 200)
                            {
                                this.$fun.alert("Realizado correctamente", "success");
                                this.$emit("closeDispatchPicking");
                                this.$emit("refreshLisDispatch");
                            }
                        });
                    }
                })
                }
               
            },   
            initialize(){
                
                _sPrePackingDispatch.list(this.SelectPrePacking, this.$fun.getUserID()).then((r) => {
                    if(r.status = 200){
                        this.PrePackingDispatch = r.data;                       
                    }
                })
            },      
            changeChamber(changeChamber){
                _sChamber.list({CdtWarehouses: changeChamber}, this.$fun.getUserID()).then((r) => {
                    if(r.status = 200)
                    {
                        this.listChamber = r.data;                        
                    }
                });
            },
            onValue(val)
            {
                let find = false;
                this.SelectPrePackingDetail.forEach((r, index) => {
                    if(val == r.PalletGroupID)
                    {  
                        this.SelectDispatch.push(r);                      
                        r.color = 1;
                        this.itemRowBackground(r);
                        this.$fun.alert("Pallet encontrado", "success");                                           
                        find = true;
                        
                    }
                });
                if(!find){
                    this.$fun.alert("Pallet no se encuentra en la lista", "info");
                }
            },
            itemRowBackground: function (item)
            {   
                if (item.color === 0) return "style-1";
                if (item.color === 1) return "style-2";
                    return "style-1";              
            },
            
            
        },
        watch:{
            SelectPrePackingDetail(){
                this.SelectPrePackingDetail.forEach((r) => {
                
                        r.color = 1;
                        this.itemRowBackground(r);
                });
            }
        },
        
        created(){
            this.initialize();
            this.SelectPrePackingDetail.forEach((r) => 
            {               
                this.itemRowBackground(r);
            });
            console.log(this.SelectPrePackingDetail);
        }
    }
</script>

<style>
.style-1 {
    background-color: white !important;
}

.style-2 {
    background-color: #00C853 !important;
}
</style>